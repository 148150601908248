.catalog {
  padding: 38px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.catalog__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  height: 88px;
  width: 100%;
}

.catalog__iconArrow {
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.catalog__iconArrow:hover {
  opacity: 0.7;
}

.catalog__iconSearch {
  width: 18px;
  height: 18px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.catalog__iconArrow:hover {
  opacity: 0.7;
}

.catalog__chipsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.catalog__filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.catalog__filtersContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.catalog__title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.catalog__buttonFilters {
  background-color: transparent;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #272121;
}

.catalog__buttonSort {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
}

.catalog__searchWrapper {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  justify-content: space-between;
  align-items: center;
  width: 316px;
  height: 48px;
  padding: 0px 12px 0px 12px;
  gap: 12px;
  border-radius: 10px 0px 0px 0px;
  border: 1.2px #dfe3e7;
}


.catalog__cardList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 32px;
    column-gap: 16px;
}