 /* origin: react-dadata | https://github.com/vitalybaev/react-dadata */

.suggestions-input__container {
  position: relative;
}

.suggestions-input__input {
  display: block;
  box-sizing: border-box;
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  padding: 0 10px;
  outline: none;
}

.suggestions-input__input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 124, 214, 0.3);
  border-color: #007CD6;
}

.suggestions-input__suggestions {
  position: absolute;
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: -2001;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 1px 6px 3px rgba(0,0,0,.1);
  top: calc(100% + 8px);
  border-radius: 5px;
  overflow: hidden;
  left: 0;
  right: 0;
  text-align: left;
  transition: opacity 0.3s ease;
}

/* TODO: убрать :has */
.validatable-input:has(input:focus) ~ .suggestions-input__suggestions {
  display: block;
  z-index: 2001;
  opacity: 1;
}

.suggestions-input__container>.validatable-input>input:focus+.validatable-input__error-msg {
  display: none;
}

.suggestions-input__suggestion-note {
  font-size: 14px;
  color: #828282;
  padding: 10px 10px 5px 10px;
}

.suggestions-input__suggestion {
  font-size: 15px;
  padding: 7px 10px;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  display: block;
  background: none;
  border: none;
  text-align: left;
}

.suggestions-input__suggestion--line-through {
  text-decoration: line-through;
}

.suggestions-input__suggestion-subtitle {
  font-size: 14px;
  margin-top: 4px;
  color: #777777;
}

.suggestions-input__suggestion-subtitle-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 4px;
}

.suggestions-input__suggestion-subtitle-item:last-child {
  margin-right: 0;
}

.suggestions-input__suggestion--current {
  background-color: rgba(0, 124, 214, 0.15);
}

.suggestions-input__suggestion:hover, .suggestions-input__suggestion.hover {
  background-color: rgba(0, 124, 214, 0.1);
}

.suggestions-input mark {
  background: none;
}

.suggestions-input--highlighted {
  color: #0094FF;
}
