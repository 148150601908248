.w-fill {
  width: -webkit-fill-available;
}

.w-shrink {
  width: fit-content;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}
