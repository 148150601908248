.modal {
  overflow-y: auto;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  max-width: 100vw;

  background: white;
  // margin-bottom: 123px;
}

.modal__content {
  padding: 25px;
  width: 500px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal__block {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.modal__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.modal__statusSuccess {
  font-size: 16px;
  font-weight: 400;
  color: green;
}

.modal__statusPending {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: blue;
}


.modal__iconClose {
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  transition: all ease .3s;
}

.modal__iconClose:hover {
  opacity: 0.8;
}