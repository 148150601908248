.fast-register-landing {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  // padding: 0 2rem;

  &__accelarators_sign {
    color: #ffffff80;
  }

  &__options {
    &__icon-column {
      display: flex;
      flex-direction: column;
      width: 20%;
      height: 100%;
      margin-right: 1rem;
    }

    &__icon_frame {
      position: relative;
      
      display: flex;
      align-items: center;
      justify-content: center;
      
      width: 100%;
      padding-bottom: 100%;  // 100 - 0 (размер иконки из-за позишн), чтобы соблюдалась пропорция 1:1
      border-radius: 50%;
    }

    &__icon {
      position: absolute;  // чтобы не влиять на внутреннее пространство (высота иконки 0) 
      top: 0;
      width: 50%;  // ширина == высота
      transform: translateY(50%);
    }

    &__text-column {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__title {
      height: 3rem;  // 3 строки
    }

    &__text {
      // height: 5rem;  // 5 строк
    }
  }
}

.fastRegistrNoBlue {
  background-color: #F1F5FD!important;
}

.fastRegistrImg {
  width: 100%;
  height: 100vh;
  background-image: url('../../static/landing/Frame\ 523.svg');
}
