// // @mixin search-open-row-width() {
// //   @media (min-width: 901px) {
// //     width: calc(100% - 20px + 10px);
// //   }

// //   @media (max-width: 900px) {
// //     width: 100%;
// //   }
// // }

// $color-primary: aqua;
// $color-secondary: yellow;
// $color-elements: grey;
// $color-selection: purple;
// $font-secondary: inherit;

// .search {
//   position: absolute;
//   right: 10px;
//   top: 0;
//   bottom: 0;
//   margin: auto;
//   width: 100%;
//   height: 100%;

//   &__button {
//     // @include icon-size;
//     // @include icon;

//     position: absolute;
//     right: 5px;
//     top: 0;
//     bottom: 0;
//     z-index: 4;
//     margin: auto;
//     border-radius: $border-radius;
//     transition: all 1s;

//     &:hover {
//       cursor: pointer;
//     }

//     &-mobile-move {
//       @media (max-width: 900px) {
//         right: 50px;
//       }
//     }
//   }

//   &__row {
//     position: absolute;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     z-index: 4;
//     display: flex;
//     flex-direction: row;
//     margin: auto;
//     margin-left: 10px;
//     outline: none;
//     border: none;
//     border-radius: $border-radius;
//     height: 50px;
//     box-sizing: border-box;
//     background-color: transparent;

//     &_open {
//       // @include search-open-row-width;

//       left: 0;
//       opacity: 1;
//       cursor: text;
//     }

//     &_close {
//       left: calc(100% - 65px + 10px);
//       width: 0;
//       opacity: 0;
//       cursor: auto;
//     }

//     &::placeholder {
//       color: $color-elements;
//       opacity: 0.5;
//     }

//     &_group_bar {
//       // @include font-size-normal;

//       padding-left: 10px;
//       width: -webkit-fill-available;
//       height: -webkit-fill-available;
//       // font-family: $font-secondary;
//       font-weight: 200;
//       letter-spacing: 0.1em;
//       color: $color-primary;
//     }

//     &_group_dropdown {
//       // @include font-size-normal;

//       position: relative;
//       display: inline-block;
//       width: 25%;
//       height: -webkit-fill-available;
//       color: $color-primary;
//     }

//     &_group_dropdown-item {
//       padding: 10px;
//       width: -webkit-fill-available;

//       &:hover {
//         background-color: $color-selection;
//       }
//     }

//     &_group_dropdown-content {
//       position: absolute;
//       z-index: 1;
//       display: none;
//       padding: 0;
//       width: -webkit-fill-available;
//       // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
//       box-sizing: border-box;
//       background-color: $color-elements;
//     }

//     &_btn {
//       display: flex;
//       border: none;
//       border-top-right-radius: $border-radius;
//       border-bottom-right-radius: $border-radius;
//       width: 55px;
//       background-color: $color-secondary;
//     }

//     &:hover {
//       cursor: pointer;
//     }
//   }

//   &__input {
//     // @include font-size-normal;

//     border-top-left-radius: $border-radius;
//     border-bottom-left-radius: $border-radius;
//     padding-left: 10px;
//     width: calc(75% - 55px);
//     height: -webkit-fill-available;
//     font-family: $font-secondary;
//     font-weight: 200;
//     letter-spacing: 0.1em;
//     color: $color-primary;
//   }

//   &:focus {
//     left: 0;
//     z-index: 3;
//     width: calc(100% - 20px);
//   }
// }

.search {
  display: flex;
  width: 20em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  // TODO:
  height: 64px;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5em;

    & i {
      display: flex;
      height: 100%;
      line-height: unset
    }
  }
  & input {
    display: flex;
    width: 5em;
    color: white;
  }
}