@use '../../variables';


.message {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 25px;

    &__messageRight {
      align-items: flex-end;
    }

&__title {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__user {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  &__content {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    max-width: 905px;
  }
}