.card {
  max-width: 190px;
  width: 100%;
  height: 374px;
  gap: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.card__img {
  width: 100%;
  height: 100%;
  border-radius: 8px 0px 0px 0px;
}

.card__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
}

.card__infoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.card__title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #242429;
  word-break: break-all;
  text-wrap: wrap;
}

.card__company {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  color: #242429;
  word-break: break-all;
  text-wrap: wrap;
}

.card__rating {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.card__ratingIcon {
  width: 12px;
  height: 12px;
}

.card__price {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #27ae60;
}

.card__button {
  width: 100%;
  transform: 'none';
  color: '#5075F9';
}

.card__buttonText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: '#FFFFFF';
}
