.news {
  width: 100%;
  margin: 85px auto;

  &__cards {
    margin-top: 50px;
    display: flex;
    gap: 24px;
  }
}
