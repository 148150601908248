@import 'carousel-variables';

.theme {
  &.carousel {
    & .indicators {
      background-color: $carousel-indicators-bg-color;

      & .indicator-item {
        background-color: $carousel-indicator-item-bg-color;
        border: 1px solid $carousel-indicator-item-border-color;
  
        &.active {
          background-color: $carousel-active-indicator-item-bg-color;
        }
      }
    }

    & .full-slide {
      height: calc(100% - 80px);
    }

    & .tranform-translate-y-0 {
      transform: translateY(-100px) !important;
    }

    &.carousel-slider[style] {
      height: 400px !important;
    }   
  }
}