@use '../../variables';

.main {
  min-width: 100vw;
  height: 630px;
  margin-bottom: 70px;

  background-color: variables.$bg_soft_grey;

  display: flex;
  align-items: center;
  flex-direction: column;

  &__form_block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    max-width: 1106px;
    height: 50px;
    background-color: white;

    margin-top: 35px;
    margin-bottom: 45px;

    border-radius: 5px 0px 0px 5px;
    &__input {
      :last-child {
        min-width: 900px;
      }
      display: flex;
      gap: 13px;
      margin-left: 13px;
    }

    &__button {
      width: 205px;
      height: 47px;
    }
  }

  &__left_section {
    background-color: #1b2b62;
    min-width: 980px;
    height: 420px;
    border-radius: 12px;

    display: flex;
    justify-content: space-between;

    &__text {
      margin-left: 40px;
      margin-top: 130px;
      margin-bottom: 111px;
      width: 450px;
      font-weight: bold;

      color: white;
    }

    &__table {
      width: 706px;
      height: 318px;

      position: absolute;
      height: 318.3px;
      left: 319px;
      float: right;
    }
  }

  &__right_section {
    background-color: variables.$soft_blue;

    min-width: 318px;
    height: 420px;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    justify-content: end;

    &__text {
      display: flex;
      flex-direction: column;

      margin-left: 24px;
      font-size: 16px;
    }
  }
}
