.footermobile {
  display: flex;
  flex-direction: column;
  margin-left: 0 !important;
  padding: 35px 35px 8px !important;

  &__upper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    
    &__left {
      display: flex;
      align-items: center;
    }

    &__right {
      div {
        display: flex;
        align-items: center;
      }
    }
  }

  &__lower {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    p {
      text-decoration-line: underline;
      color: rgba(112, 120, 149, 0.7);
    }
  }
}