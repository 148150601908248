.card {
  width: 215px;
  padding: 0 10px 10px;
  box-shadow: none;

  &__img {
    display: block;
    height: 160px;
    margin: 0 auto;
    grid-area: 'img';
  }

  &__header {
    display: flex;
    justify-content: space-between;
    grid-area: 'header';
  }

  &__rating {
    display: flex;
    color: #707895;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    grid-area: 'rating';

    img:last-child {
      margin-right: 5px;
    }
  }

  &__articul {
    color: #707895;
    font-size: 10px;
    grid-area: 'articul';
  }

  &__name {
    color: #272121;
    font-size: 11px;
    margin: 5px 0;
    max-height: 27px;
    overflow: hidden;
    grid-area: 'name';
  }

  &__company {
    cursor: pointer;
    display: block;
    font-size: 11px;
    color: #707895;
    margin-bottom: 10px;
    grid-area: 'company';

    &:hover {
      opacity: 0.7;
    }
  }

  &__pricing {
    color: #707895;
    font-size: 10px;
    grid-area: 'pricing';

    &-item {
      position: relative;
      display: inline-block;
      margin-left: 6px;

      &:hover {
        cursor: pointer;
        color: #3e74dd;

        &:after {
          position: absolute;
          display: block;
          content: '';
          width: 100%;
          height: 0.5px;
          background: #3e74dd;
        }
      }
    }
  }

  &__price {
    display: block;
    color: #272121;
    font-weight: 600;
    font-size: 16px;
    grid-area: 'price';
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    grid-area: 'footer';
  }

  &__btn {
    width: max-content;
    border-radius: 5px;
    font-size: 12px;
    padding: 4px 12px;
    line-height: 20px;
    height: max-content;
    grid-area: 'btn';
  }

  &__helper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    grid-area: 'helper';
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 68px;
    grid-area: 'icons';
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__counter {
    display: flex;
    width: 65px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    grid-area: 'counter';

    &-btn {
      line-height: 0px;
      padding: 0;
      width: 13px;
      height: 13px;
      font-size: 8px;
      border-radius: 50%;
    }
  }

  &__descr {
    grid-area: 'descr';
    display: none;
  }
}

.card.list-layout {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .card__img {
    height: auto;
    width: 140px;
    margin-right: 28px;
  }

  .card__name {
    font-size: 20px;
    margin-right: 72px;
    max-height: 57px;
    overflow: initial;
  }

  .card__info {
    display: flex;
    margin-left: auto;
  }

  .card__pricing {
    width: max-content;
    margin-bottom: 6px;
  }

  .card__price {
    text-align: right;
  }

  .card__icons {
    display: flex;
    flex-direction: column;

    img {
      margin-bottom: 10px;
    }
  }

  .card__counter {
    margin-bottom: 6px;
    margin-right: 9px;
  }

  .card__footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: end;
    margin-right: 30px;
  }
}
