@use '../../../variables';
.main {
  height: auto;
  background-image: url(../../../../static/landing/bitubi.svg);
  display: flex;
  border-radius: 12px;

  &__banner {
    width: 412px;
    margin-bottom: 19px;
  }
}

.inner_main {
  margin-left: 43px;
  margin-bottom: 43px;
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  align-items: start;
  justify-content: flex-end;
}

@media (max-width: 414px) {
  
}

@media screen and (max-width: 576px){
  .inner_main {
    margin: 40px 20px 26px;
  }
}