@use '../../../variables';
.forms_field {
  // margin-left: 63px;
  // margin-right: 63px;
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 540px;
  * {
    margin-bottom: 5px;
  }
}

.form_block {
  margin-bottom: 50px;
  width: 460px;
}

.button {
  float: right;
  width: 50%;
}
