@each $i in 0, 5, 10, 20 {
  .m-#{$i} {
    margin: #{$i}px;
  }
  .mv-#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }

  .mh-#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }

  .mt-#{$i} {
    margin-top: #{$i}px;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }

  .ml-#{$i} {
    margin-left: #{$i}px;
  }

  .mr-#{$i} {
    margin-right: #{$i}px;
  }
}

.m-auto {
  margin: auto;
}

.mh-auto {
  margin: 0 auto;
}

.mv-auto {
  margin: auto 0;
}
