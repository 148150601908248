@import 'cartItem-variables';

.cart__item {
    display: flex; 
    box-shadow: 0px 0px 3px 2px #00000012;
    margin-bottom: 13px;
    padding: 20px 17px;
    border-radius: 12px;
    width: max-content;

    &-checkbox {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    &-img {
        margin-right: 25px;
    }

    &-title {
        font-size: 16px;
        width: 345px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 5px;
        margin-top: 0;
    }

    &-company {
        margin-top: 0;
        font-size: 12px;
        color: #707895;
    }

    &-icons {
        display: flex;
        flex-direction: column;
    }

    &-link {
        display: block;
        margin-bottom: 34px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-pricing {
        margin-right: 46px;
    }

    &-price {
        display: block;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 31px;
    }
}