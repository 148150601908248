@use "sass:map";

$theme-background-primary: #f4f7ff; // Lucent White
$theme-background-secondary: #375BA1;
$theme-background-accent: #558AF0;
$theme-background-card: #f1f4fe;
// $theme-landing-bg: #0B1E36;
$theme-landing: #1b2c62;
$theme-black: #18181a; // Gun Powder
$theme-text-secondary: #707895;
$theme-text-primary: #272121;

$theme-grey: map.get($colors, 'grey', 'darken-3');   // #424242;
$theme-bkgd: map.get($colors, 'grey', 'lighten-5');  // #fafafa;

$theme-background-registr: #F1F5FD;

$theme-colors: (
  'white': $theme-background-primary,
  'white-card': $theme-background-card,
  'blue-accent': $theme-background-accent,
  'blue': $theme-background-secondary,
  'black': $theme-black,
  'landing': $theme-landing,
  'registr': $theme-background-registr,
) !default;

.text-secondary {
  color: $theme-text-secondary;
}



@each $color_name, $color_value in $theme-colors {
  .theme-#{$color_name} {
    background-color: $color_value !important;
  }
  .theme-#{$color_name}-text {
    color: $color_value !important;
  }
}

// usage: color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)

@function theme-color($color) {
  @if map-has-key($theme-colors, $color) {
    @return map-get($theme-colors, $color);
  }
  @warn "Unknown `#{$color}` in $theme-colors.";
  @return null;
}
