@use '../../pages/variables';
.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 65px;
  margin-bottom: 10px;
}

.upper {
  display: flex;
  justify-content: center;
  max-width: 1310px;
  gap: 421px;
  margin-bottom: 20px;
}

.upper__left {
  display: inline-flex;
  gap: 96px;
  flex-shrink: 1;
}

.upper__right {
  display: inline-flex;
}

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: variables.$text_grey;
  * {
    display: flex;
    gap: 10px;
  }
}

.lower {
  display: inline-flex;
  justify-content: space-between;
  width: 1310px;
  padding-top: 5px;
  border-top: 1px rgb(112, 120, 149, 0.5) solid;
}

.lower__left {
  display: flex;
  color: variables.$text_grey;
  gap: 40px;
  text-decoration: underline;
  * {
    font-size: 10px;
  }
}

.lower__right {
  color: variables.$soft_blue;
}

.footer__sponsors-img {
  width: 100%;
}
