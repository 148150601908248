form {
  & .register {
    &.carousel {
      height: 700px;
    }

    &__stage {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      // in order to content would not intersect with indicators 
      padding-bottom: 2.5rem;

      &__fields {
        display: flex;
        width: 100%;
        flex-direction: column;
      }

      &__buttons {
        width: 100%;
      }
    }

    &__company {
      opacity: 0;
      transition: opacity .5s ease-in-out;

      &.active {
        opacity: 1;
      }
    }
  }

  &.fastorder {
    & .validatable-input.input-field {
      margin-top: 0;
    }
  }
}
