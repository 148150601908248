
.d-flex {
  display: flex;
}

.jc-sp-btw {
  display: flex;
  justify-content: space-between;
}

.jc-sp-e {
  display: flex;
  justify-content: space-evenly;
}

.jc-sp-a {
  display: flex;
  justify-content: space-around;
}

.l-container {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
}

.l-row {
 display: flex;
 flex-direction: row;
 align-items: center;
}

.l-col {
 display: flex;
 flex-direction: column;
}

.l-right {
 display: flex;
 justify-content: end;
 width: 100%;
}

.l-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.l-baseline {
 display: flex;
 align-items: baseline;
}

.l-table {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

@each $i in 1, 2, 3, 4 {
  .flex-grow-#{$i} {
    flex-grow: #{$i};
  }
}
