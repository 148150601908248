.categoryCard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 300px;
  height: 400px;
  background: #f5f5f5;
  transition: all ease 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

@media screen and (max-width: 480px) {
  .categoryCard {
    height: 48px;
    background: #edf1f8;
  }
}

.categoryCard__content {
  display: flex;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  flex-direction: column;
  gap: 0.75rem;
}

@media screen and (max-width: 480px) {
  .categoryCard__content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.categoryCard__image {
  border-radius: 0.5rem;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .categoryCard__image {
    display: none;
  }
}

.categoryCard__text {
  font-weight: 600;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .categoryCard__text {
    text-align: left;
  }
}

.categoryCard__arrow {
  width: 12px;
  height: 6px;
  cursor: pointer;
}
