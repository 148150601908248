@use '../../../variables';
.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  height: 165px;
  margin-top: 10px;
  background-color: white;
  border-radius: 12px;
  padding-left: 5%;

  div {
    margin: auto;
    :first-child {
      font-size: 28px;
      color: variables.$soft_blue;
    }
    :last-child {
      font-size: 14px;
      color: variables.$text_grey;
    }
  }
}

@media (max-width: 750px) {
  .grid {
    display: flex;
    flex-direction: column;
    
    height: fit-content;

    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;

    div {
      margin: auto;
      :first-child {
        font-size: 28px;
        color: variables.$soft_blue;
      }
      :last-child {
        font-size: 14px;
        color: variables.$text_grey;
      }
    }
  }
}
