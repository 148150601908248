@use '../../variables';
.main {
  background-color: variables.$bg_main;
  height: 661px;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner_main {
  min-width: 90%;
  height: 540px;
  display: flex;
  justify-content: center;
}

.left {
  max-width: 710px;
  display: flex;
  flex-direction: column;
}

.right {
  max-width: 585px;
  margin-left: 15px;
  display: flex;
  background-color: white;
  border-radius: 12px;
}

@media (max-width: 1270px) {
  .left {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 576px) {
  .main {
    padding: 50px 45px 0;
  }
}