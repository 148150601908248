@use '../../variables';

.chatslist {
  min-height: 80vh;
  max-width: 296px;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.07);
  background: #f4f8ff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 310px;
  padding: 26px 26px;
  gap: 30px;
  height: 700px;
  overflow: hidden;
  // overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color:   #558AF0;
    width: 8px;
    border-radius: 10px;
  }
  
 &::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: #F4F8FF;
  }

  @media screen and (max-width: 580px) {
    display: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 258px;
    height: 118px;
    gap: 10px;
    border-bottom: 1px solid #1d365324;
    transition: all ease 0.3s;
    padding-bottom: 5px;

    &:hover {
      opacity: 0.8;
    }
  }


  &__title {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__message {
    width: 258px;
    overflow: hidden;
    height: 32px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #707895cc;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__delete {
    width: 15px;
    height: 15px;
    background-image: url('../../../static/imgchat/icon-delete.png');
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    transition: all ease 0.2s;
    zoom: 1;
    &:hover {
      opacity: 0.8;
      zoom: 1.2;
    }
  }
}
