.modal {
  overflow-y: auto;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  max-width: 100vw;

  background: white;
  // margin-bottom: 123px;
}