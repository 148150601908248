@use '../../pages/variables';

.header {
  display: flex;
  justify-content: space-between;
  margin-left: 100px;
  margin-right: 100px;

  color: variables.$text_primary;
  font-size: 16px;

  &__left {
    display: flex;
    align-items: center;

    gap: 35px;
  }
  

  &__right {
    display: flex;
    align-items: center;
    gap: 35px;
    &__profile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 480px) {
  .header {
  display: none;
  }
}