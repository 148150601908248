// colors
$white: #ffffff;
$soft_blue: #558af0;
$dark_blue: #1f3e7c;
$text_primary: #272121;
$text_grey: #707895;
$text_blue: #0e0d39;
$bg_main: #e5eeff;
$bg_input: #fafcff;
$button_bg: #375ba1;
$link_text: #3e74dd;
$cancelling: #ff6c6c;
$bg_secondary: #375BA2;
$bg_soft_grey: #F1F5FD; 

// links
$bg_bitubi: url('./bitubi.svg');

// values
$mobile_padding: 45px;