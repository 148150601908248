@use '../../../pages/variables';
.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  border-radius: 12px;

  background: white;
  z-index: 9999;
}

.hdiw {
  color: variables.$text_primary;
}

.main {
  :first-child {
    color: variables.$soft_blue;
    font-size: 28px;
    font-weight: 600;
  }
  :last-child {
    margin-bottom: 24px;
    font-size: 16px;
    color: variables.$text_grey;
  }
}

.sendButton {
  background-color: variables.$button_bg !important;
  font-weight: bold;
}
