.btn-theme {
  // @extend .theme-green;
  font-size: 1rem;
  border-radius: 2px;
  border: none;
  padding: 0.25rem 0;
  margin: 0;
  width: 100%;
  letter-spacing: 0.5px;

  &:focus {
    // @extend .theme-green;
  }
}
