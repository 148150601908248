@use '../../../pages/variables';
.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 365px;
  border-radius: 12px;

  background: white;
}

.main {
  margin-top: 40px;
  margin-bottom: 40px;
  :first-child {
    font-size: 28px;
    font-weight: 600;
    color: variables.$text_primary;
  }
  :last-child {
    color: variables.$text_grey;
    font-size: 16px;
  }
}

.sendButton {
  background-color: variables.$button_bg !important;
  font-weight: bold;
}

.footer {
  color: variables.$link_text;
}