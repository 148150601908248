input {
  &.theme {
    &[type="checkbox"].filled-in:checked+span:not(.lever):after {
      border: 2px solid theme-color('blue');
      background-color: theme-color('blue');
    }
  }
}


input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
  &.theme {
    border: 1px solid $theme-text-secondary;
    border-radius: 8px;
    padding: 1rem .75rem;
  }
}
