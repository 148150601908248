.categoryPage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px 16px 12px 16px;
}

@media screen and (max-width: 480px) {
  .categoryPage {
    flex-direction: column;
  }
}

.categoryPage__content {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 90%;
}

.categoryPage__title {
  height: 64px;
  gap: 0px;
  display: flex;
  flex-direction: row;
  gap: 95px;
  width: 100%;
  align-content: center;
}

.categoryPage__close {
  background-image: url('../static/icons/categories/Close.svg');
  cursor: pointer;
  width: 17.89px;
  height: 17.89px;

  &:hover {
    opacity: 0.5;
  }
}

.categoryPage__titleText {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin: 0;
}
