$plate-margin:          2rem 0;
$plate-padding:         2rem;
$plate-small-margin:    1rem 0;
$plate-small-padding:   1rem;

.plate, .plate-flat, .plate-small {
  // @extend .z-depth-2;

  &[class^='l-'], &.d-flex {
    display: flex;
  }
}

.plate {
  display: block;
  border-radius: $border-radius;
  padding: $plate-padding;
}

.plate-flat {
  border-radius: $border-radius;
  padding: $plate-small-padding;
}

.plate-small {
  display: block;
  border-radius: $border-radius-small;
  padding: $plate-small-padding;
}
