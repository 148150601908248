.container {
  background-image: url(../../static/landing/authField.svg);
  min-width: 100vw;
  height: 280px;
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_without_img {
  background-color: #0B1E36;
  min-width: 100vw;
  height: 280px;
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner_container {
  width: 700px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  :first-child {
    font-size: 40px;
    color: white;
  }
  * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // .button {
  //   background-color: white; 
  //   height: 64px;
  //   width: 364px;
  //   font-size: 24px
  // }
}

@media screen and (max-width: 576px) {
  .container {
    height: 230px;
    margin-top: 0;
  }

  .inner_container {
    :first-child {
      font-size: 18px;
      text-align: center;
    }
  }
}