.p-0 {
  padding: 0 !important;
}

.ph-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pv-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
