@use '../../variables';

.windowchat {
  margin: 20px 20px;
  display: flex;
  flex-direction: column;

  &__message {
    width: 100%;
    padding: 20px 22px 0 22px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    // overflow-y: scroll;
    &_scroll {
      overflow-y: scroll;
      width: 100%;
      padding: 20px 22px 0 22px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 10px;
      max-height: 500px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #558af0;
        width: 8px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
        background-color: #f4f8ff;
      }
    }
  }

  &__data {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #707895;
  }

  &__title {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 25px;
  }
  &__user {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  &__content {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    max-width: 905px;
  }

  &__form {
    height: 50px;
    background-color: #f4f8ff;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__input {
    font-size: 16px;
    width: 100px;
  }

  &__menu {
    width: 16px;
    height: 16px;
    background-image: url('../../../static/imgchat/icon-menu.svg');
    background-repeat: no-repeat;
    &:hover {
      opacity: 0.8;
      zoom: 1.2;
    }
  }

  &__scrap {
    width: 20px;
    height: 18px;
    background-image: url('../../../static/imgchat/icon-menu.svg');
    background-repeat: no-repeat;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      zoom: 1.2;
    }
  }

  &__send {
    width: 18px;
    height: 17px;
    background-image: url('../../../static/imgchat/icon-send.svg');
    background-repeat: no-repeat;
    &:hover {
      opacity: 0.8;
      zoom: 1.2;
    }
  }
}

.input__file {
  display: none;
}
